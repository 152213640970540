import React, { useState, Suspense } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Layout, Menu, Spin } from 'antd';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { PieChartOutlined } from '@ant-design/icons';
import Routes from '../routes/Main';
import DashboardHeader from './shared/dashboardHeader';
import { logout, selectCurrentUser } from '../app/userSlice';


const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const AppLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const onCollapse = (collapsed : boolean) => {
    setIsCollapsed(collapsed);
  };

  const logoutUser = () => {
    dispatch(logout({}));
  };

  return (
    <Suspense fallback={<Spin spinning />}>
      <Router>
        <Layout style={{ minHeight: '100vh', minWidth: '20%' }}>
          <Sider
            breakpoint="md"
            collapsedWidth="0"
            collapsible
            collapsed={isCollapsed}
            onCollapse={onCollapse}
          >
            <div className="logo-wrapper">
              Logo here
            </div>
            <Menu defaultSelectedKeys={['2']} mode="inline" theme="dark">
              <Menu.Item key="2" icon={<PieChartOutlined />}>
                <Link to="/">Dashboard</Link>
              </Menu.Item>
              <SubMenu key="sub2" icon={<PieChartOutlined />} title="CONTENT MANAGEMENT">
              <Menu.Item key="1" icon={<PieChartOutlined />}>
                <Link to="/content-management/add-item">ADD ITEMS</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<PieChartOutlined />}>
                <Link to="/content-management/edit-items">Edit ITEM</Link>
              </Menu.Item>
            </SubMenu>
              <Menu.Item key="3" icon={<PieChartOutlined />}>
                <Link to="/algorithms-containers">ALGORITHMS</Link>
              </Menu.Item>
              <Menu.Item key="4" icon={<PieChartOutlined />}>
                <Link to="/languages">LANGUAGES</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<PieChartOutlined />}>
                <Link to="/topics">TOPICS</Link>
              </Menu.Item>
              <Menu.Item key="6" icon={<PieChartOutlined />}>
                <Link to="/users">USERS</Link>
              </Menu.Item>
              <Menu.Item key="7" icon={<PieChartOutlined />}>
                <Link to="/profiles">TEST LAB</Link>
              </Menu.Item>
              <Menu.Item key="8" icon={<PieChartOutlined />}>
                <Link to="/import-uuids">IMPORT UUIDS</Link>
              </Menu.Item>
              <Menu.Item key="9" icon={<PieChartOutlined />}>
                <Link to="/videos">VIDEOS</Link>
              </Menu.Item>
              <Menu.Item key="10" icon={<PieChartOutlined />}>
                <Link to="/static-pages">STATIC PAGES</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <DashboardHeader logout={logoutUser} name={user.firstName} url={user.profilePicture}/>
            <Content>
              <div style={{ padding: 24, minHeight: '100vh' }}>
                <Routes />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Router>
    </Suspense>
  );
};

export default AppLayout;
