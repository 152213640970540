import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const DashBoard = lazy(() => import('../pages/dashboard/dashboardContainer'));
const UsersPage = lazy(() => import('../pages/users/usersContainer'));
const ContentManagement = lazy(() => import('../pages/contentManagement/contentManagementContainer'));
const AddItems = lazy(() => import('../pages/contentManagement/add/addLayout'));
const Algorithims = lazy(() => import('../pages/algorithimsView/algorithimsViewContainer'));
const AlgorithimsContainers = lazy(() => import('../pages/algorithimsContainersView/algorithimsContainersViewContainer'));
const Templates = lazy(() => import('../pages/templates/templatesContainer'));
const Languages = lazy(() => import('../pages/languagesManagement/languagesManagementContainer'));
const Topics = lazy(() => import('../pages/topicsManagement/topicsManagementContainer'));
const Rules = lazy(() => import('../pages/manageRules/algorithimsViewContainer'));
const Profiles = lazy(() => import('../pages/profiles/profilesContainer'));
const ProfilePresentations = lazy(() => import('../pages/profilePresentatins/profilePresentationsContainer'));
const importUUIDS = lazy(() => import('../pages/importUUIDS/importUUIDSContainer'));
const Videos = lazy(() => import('../pages/videosView/videosViewContainer'));
const StaticPages = lazy(() => import('../pages/staticPages/staticPagesContainer'));
const StaticPagesEdit = lazy(() => import('../pages/staticPages/staticPagesEdit'));
const StaticPageView = lazy(() => import( '../pages/staticPages/staticPageView/staticPageViewContainer'));

const Main = () => (
  <Switch>
    <Route exact path="/" component={DashBoard} />
    <Route exact path="/users" component={UsersPage} />
    <Route exact path="/content-management/add-item" component={AddItems} />
    <Route exact path="/content-management/edit-items" component={ContentManagement} />
    <Route exact path="/algorithms-containers" component={AlgorithimsContainers} />
    <Route exact path="/algorithmsContainer/:id/:algorithmContainerName/algorithims" component={Algorithims} />
    <Route exact path="/algorithmsContainer/:id/:algorithmContainerName/rules" component={Rules} />
    <Route exact path="/algorithmsContainer/:algorithmContainerId/:algorithmContainerName/algorithms/:id/:title/templates" component={Templates} />
    <Route exact path="/languages" component={Languages} />
    <Route exact path="/videos" component={Videos} />
    <Route exact path="/topics" component={Topics} />
    <Route exact path="/profiles" component={Profiles} />
    <Route exact path="/profile/:id/presentations" component={ProfilePresentations} />
    <Route exact path="/import-uuids" component={importUUIDS} />
    <Route exact path="/static-pages" component={StaticPages} />
    <Route exact path="/static-pages/edit/:path" component={StaticPagesEdit} />
    <Route exact path="/static-pages/create/:path" component={StaticPagesEdit} />
    <Route exact path="/static-pages/view/:path" component={StaticPageView} />
    <Route exact path="/login">
      <Redirect to="/" />
    </Route>
  </Switch>
);
export default Main;