import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../pages/login/loginSlice';
import userReducer from './userSlice';
import usersReducer from '../pages/users/usersSlice';
import contentManagementReducer from '../pages/contentManagement/contentManagementSlice';
import algorithimsReducer from '../pages/algorithimsView/algorithimsViewSlice';
import algorithimsContainersReducer from '../pages/algorithimsContainersView/algorithimsContainersViewSlice';
import templatesReducer from '../pages/templates/templatesSlice';
import languagesReducer from '../pages/languagesManagement/languagesManagementSlice';
import topicsReducer from '../pages/topicsManagement/topicsManagementSlice';
import rulesReducer from '../pages/manageRules/rulesSlice';
import profilesReducer from '../pages/profiles/profilesSlice';
import profilePresentationsReducer from '../pages/profilePresentatins/profilePresentationsSlice';
import importUUIDSReducer from '../pages/importUUIDS/importUUIDSSlice';
import videosReducer from '../pages/videosView/videosViewSlice';
import dashboardReducer from '../pages/dashboard/dashboardSlice';
import staticPagesReducer from '../pages/staticPages/staticPagesSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    login: loginReducer,
    users: usersReducer,
    conetentManagement: contentManagementReducer,
    algorithims: algorithimsReducer,
    templates: templatesReducer,
    languages: languagesReducer,
    topics: topicsReducer,
    algorithimsContainers: algorithimsContainersReducer,
    rules: rulesReducer,
    profiles: profilesReducer,
    profilePresentatins: profilePresentationsReducer,
    importUUIDS: importUUIDSReducer,
    videos: videosReducer,
    dashboard: dashboardReducer,
    staticPages: staticPagesReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
