import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RootState, AppThunk } from '../../app/store';
import { v4 as uuid} from 'uuid';
import moment from 'moment';
import { message as antMessage } from 'antd';
import axios from 'axios';
import { message } from '../../utils';
import { API_URL } from '../../config'

const initialCurrentStaticPage = { id: '', name: '', path: '', content: '', createdAt: '', updatedAt: ''};
interface StaticPage {
  id: string
  name: string
  path: string
  updatedAt: string
  createdAt: string
  content: string
}
interface updateStatus {
  updateStatus: boolean
}
interface LoadingStatus {
  isLoading: boolean
}

interface StaticPagesState {
  staticPages: StaticPage[]
  currentStaticPage: StaticPage,
  isLoading: boolean
  updateStatus: boolean
}


const initialState: StaticPagesState = {
  staticPages: [],
  currentStaticPage: initialCurrentStaticPage,
  isLoading: false,
  updateStatus: false
};

export const staticPagesSlice = createSlice({
  name: 'staticPages',
  initialState,
  reducers: {
    changeStaticPages: (state, action: PayloadAction<{ staticPages: StaticPage[] }>) => {
      return { ...state, ...action.payload } 
    },
    changeCurrentStaticPage: (state, action: PayloadAction<{ currentStaticPage: StaticPage }>) => {
      return { ...state, ...action.payload } 
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload } 
    },
    changeUpdateStatus: (state, action: PayloadAction<updateStatus>) => {
      return { ...state, ...action.payload } 
    },
    
  },
});

export const { changeStaticPages, setLoadingStatus } = staticPagesSlice.actions;


export const getStaticPages = (token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { staticPages } }} = await axios({
      method: 'GET',
      url: `${API_URL}/static-pages`,
      headers: {
        Authorization: token
      }
    });
    const formattedStaticPages = staticPages.map((staticPage: any) => ({
      ...staticPage,
      createdAt: moment(staticPage.created_at).format('L'),
      updatedAt: moment(staticPage.updated_at).format('L'),
    }))
    dispatch(staticPagesSlice.actions.changeStaticPages({ staticPages: formattedStaticPages }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const getStaticPageByPath = (path: string, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  
  try{
    dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: true }));
    const { data : { results: { staticPage } }} = await axios({
      method: 'GET',
      url: `${API_URL}/static-pages/path/${path}`,
      headers: {
        Authorization: token
      }
    });
    const formattedStaticPage = {
      ...staticPage,
      createdAt: moment(staticPage.created_at).format('L'),
      updatedAt: moment(staticPage.updated_at).format('L'),
    } as StaticPage;
    dispatch(staticPagesSlice.actions.changeUpdateStatus({ updateStatus: true }));
    dispatch(staticPagesSlice.actions.changeCurrentStaticPage({ currentStaticPage: formattedStaticPage }));
  } catch(err) {
    message.errorMessage(err, messageKey);
  } finally {
    dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: false }));
  }
};

export const addStaticPage = (staticPage: any ,token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    console.log(staticPage.name.length, staticPage.path.length, staticPage.content.length)

    if(staticPage.name.length < 3 || staticPage.path.length < 3 || staticPage.content.length === 0) { 
      message.errorMessage("name, path and content are required", messageKey)
      return;
    }
    
    message.loadingMessage('adding static page...', messageKey, 0);
    try{
      dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { addedStaticPage } } } = await axios({
        method: 'POST',
        url: `${API_URL}/static-pages`,
        data: staticPage,
        headers: {
          Authorization: token
        }
      });
      const formatted = {
        ...addedStaticPage,
        createdAt: moment(addedStaticPage.created_at).format('L'),
        updatedAt: moment(addedStaticPage.updated_at).format('L'),
      }
      // dispatch(staticPagesSlice.actions.changeStaticPages({ staticPages: [...addedStaticPages, formatted]}));
      message.successMessage('added successfully', messageKey);
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateStaticPage = (id: string, staticPage: any, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    
    message.loadingMessage('updating page...', messageKey, 0);
    try{
      dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results : { updatedStaticPage } } } = await axios({
        method: 'PUT',
        url: `${API_URL}/static-pages/${id}`,
        data: staticPage,
        headers: {
          Authorization: token
        }
      });
      dispatch(staticPagesSlice.actions.changeUpdateStatus({ updateStatus: true }));

      message.successMessage('updated successfully', messageKey);
    } catch(err) {
      dispatch(staticPagesSlice.actions.changeUpdateStatus({ updateStatus: false }));
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

  export const deleteStaticPage = (staticPageId: string, addedStaticPages: StaticPage[], token: string): AppThunk => 
    async (dispatch) => {
      const messageKey = uuid();
      message.loadingMessage('Deleting Page...', messageKey, 0);
      
      try{
        dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: true }));
        await axios.delete(`${API_URL}/static-pages/${staticPageId}`, {
          headers: {
            Authorization: token
          }
        });
        const staticPages = addedStaticPages
          .filter((staticPage) => staticPage.id !== staticPageId)
          .map((staticPage, index) => ({...staticPage, index}));

        dispatch(staticPagesSlice.actions.changeStaticPages({ staticPages }));
        message.successMessage('deleted successfully', messageKey);
      } catch(err) {
        message.errorMessage(err, messageKey);
      } finally {
        dispatch(staticPagesSlice.actions.setLoadingStatus({ isLoading: false }));
      }
    };


export const changeCurrentStaticPage = (currentStaticPage: StaticPage): AppThunk => 
async (dispatch) => {

  dispatch(staticPagesSlice.actions.changeCurrentStaticPage({ currentStaticPage }));
  
};



export const selectStaticPages = (state: RootState) => state.staticPages;

export default staticPagesSlice.reducer;
