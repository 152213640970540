import { ReactText } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { message as antMessage } from 'antd';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { RootState, AppThunk } from '../../app/store';
import _ from 'lodash';
import { message, generateSuggestedImagesTemplate, addMarkDownToPic, uploadImageFile } from '../../utils';
import { API_URL } from '../../config'

interface Language {
  id: string
  key: string
  language: string
  isPublished: boolean
}

interface Interest {
  id: string
  interest: string
}

interface Topic {
  id: string
  topic: string
  interestId: string
  topicId: string
}

interface Audio {
  play: boolean
  record: boolean
  blob: string
}

interface Pagination {
  current: number
  pageSize: number
  total: number
}

interface MarkdownImage {
  baseImage: string
  markdown: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | '',
  url: string
  width: number
  height: number
}
interface LoadingStatus {
  isLoading: boolean
}

interface Id {
  id: string
}

interface Index {
  index: number
}

type Languages = Language[]

type Interests = Interest[]

type Topics = Topic[]

type Filters = {
  originalLanguage: [string] | []
  targetLanguage: [string] | []
  interest: [string] | []
  topic: [string] | []
}

interface Crop {
  unit: "px" | "%" | undefined
  x: number
  y: number
  width: number
  height: number
  aspect: number
}

interface Img {
  url: string
  description: string
  height?: any
  width?: any
}
interface Item {
  id: string
  suggestedImages: any[]
  originalLabel: string
  targetLabel: string
  originalLanguage: string
  targetLanguage: string
  originalAudio: string[]
  targetAudio: string[]
  originalAudioIndex: number
  targetAudioIndex: number
  interestId: string
  topicId: string
  suggestedImage: string
  markdown: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | ''
  page: number
  searchText: string
  originalSequence: number
  targetSequence: number
  originalLang?: string
  targetLang?: string
  crop: Crop
  isPublished: boolean,
  notes: string,
  hasImage: boolean,
  hasOriginalAudio: boolean,
  hasTargetAudio: boolean,
}

interface OriginalLanguage {
  originalLanguage: string
}

interface TargetLanguage {
  targetLanguage: string
}

interface InterestId {
  interestId: string,
  topicId: string
}

interface TopicId {
  topicId: string
}

interface ItemsState {
  list: [Item] | []
  isLoading: boolean
  // pagination: Pagination
}

interface ContentManagementState {
  languages: Languages
  interests: Interests
  topics: Topics
  filters: Filters
  isLoading: boolean,
  items: ItemsState,
  currentItem: Item,
  editedCurrentItem: Item,
  originalAudio: Audio
  targetAudio: Audio
  addedItems: Item[]
  selectedSlide: number
  originalLanguage: string
  targetLanguage: string
  img: Img
  isFetching: boolean
  pagination: Pagination
  interestId: string,
  topicId: string,
  originalLabelSearchText: string,
  targetLabelSearchText: string,
  interestSearchText: string,
  topicSearchText: string,
  notesSearchText: string,
  searchColumn: string
}

const initialItem = {
  id: "",
  suggestedImages: [],
  suggestedImage: "",
  originalLabel: "",
  targetLabel: "",
  originalLanguage: "",
  originalAudio: [],
  targetAudio: [],
  originalAudioIndex: 0,
  targetAudioIndex: 0,
  interestId: "",
  topicId: "",
  targetLanguage: "",
  markdown: "top-left" as const,
  originalSequence: 1,
  targetSequence: 1,
  page: 1,
  searchText: "",
  crop: {
    unit: undefined,
    x: 106.9666748046875,
    y: 15.25,
    height: 260.4375,
    width: 463,
    aspect: 1.7777777777777777,
  },
  isPublished: false,
  notes: '',
  hasImage: false,
  hasOriginalAudio: false,
  hasTargetAudio: false
};

const initialState: ContentManagementState = {
  languages: [],
  interests: [],
  topics: [],
  filters: {
    originalLanguage: [],
    targetLanguage: [],
    interest: [],
    topic: []
  },
  isLoading: false,
  items: {
    list: [],
    isLoading: false,
    // pagination: {
    //   current: 0,
    //   pageSize: 1,
    //   total: 0,
    // },
  },
  currentItem: initialItem,
  editedCurrentItem: initialItem,
  originalAudio: {
    play: false,
    record: false,
    blob: ''
  },
  targetAudio: {
    play: false,
    record: false,
    blob: ''
  },
  addedItems: [initialItem],
  selectedSlide: 0,
  originalLanguage: '',
  targetLanguage: '',
  interestId: '',
  topicId: '',
  img: {
    url: '',
    description: '',
    height: 290,
    width: 350
  },
  isFetching: false,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 10,
  },
  originalLabelSearchText: '',
  targetLabelSearchText: '',
  interestSearchText: '',
  topicSearchText: '',
  notesSearchText: '',
  searchColumn: ''
};

export const contentManagementSlice = createSlice({
  name: 'contentManagements',
  initialState,
  reducers: {
    changeLanguages: (state, action: PayloadAction<Languages>) => {
      return { ...state, ...action.payload }
    },
    chagneInterests: (state, action: PayloadAction<Interests>) => {
      return { ...state, ...action.payload }
    },
    changeInitials: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload }
    },
    changeItems: (state, action: PayloadAction<Interests>) => {
      return { ...state, ...action.payload }
    },
    changeFilters: (state, action: PayloadAction<Filters>) => {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    },
    changeCurrentItem: (state, action: PayloadAction<Id>) => {
      const currentItem = state.addedItems.filter((item: Item) => item.id === action.payload.id)[0];
      return {
        ...state,
        currentItem,
        originalAudio: { ...state.originalAudio, blob: currentItem.originalAudio[currentItem.originalAudioIndex] },
        targetAudio: { ...state.targetAudio, blob: currentItem.targetAudio[currentItem.targetAudioIndex] },
      };
    },
    changeMarkdownLocation: (state, action: PayloadAction<MarkdownImage>) => {
      const { suggestedImage, suggestedImages } = state.currentItem;
      const { baseImage, markdown, url, width, height } = action.payload;
      const newSuggestedImages = suggestedImages
        .map((image: any) => (image.id === suggestedImage) ?
          generateSuggestedImagesTemplate(suggestedImage, url, baseImage,  width, height)
          : image);
      const newItem = { ...state.currentItem, suggestedImages: newSuggestedImages, markdown };
      const addedItems = state.addedItems
        .map((item: Item) => (item.id === state.currentItem.id) ? newItem : item);

      return { ...state, currentItem: newItem, addedItems }
    },
    changeOriginalLanguage: (state, action: PayloadAction<OriginalLanguage>) => {
      const addedItems = state.addedItems.map((item: Item) => ({ ...item, ...action.payload }))
      return { ...state, ...action.payload, addedItems, currentItem: { ...state.currentItem, ...action.payload } }
    },
    changeTargetLanguage: (state, action: PayloadAction<TargetLanguage>) => {
      const addedItems = state.addedItems.map((item: Item) => ({ ...item, ...action.payload }))
      return { ...state, ...action.payload, addedItems, currentItem: { ...state.currentItem, ...action.payload } }
    },
    changeInterest: (state, action: PayloadAction<InterestId>) => {
      const addedItems = state.addedItems.map((item: Item) => ({ ...item, ...action.payload }))
      return { ...state, ...action.payload, addedItems, currentItem: { ...state.currentItem, ...action.payload } }
    },
    changeTopic: (state, action: PayloadAction<TopicId>) => {
      const addedItems = state.addedItems.map((item: Item) => ({ ...item, ...action.payload }))
      return { ...state, ...action.payload, addedItems, currentItem: { ...state.currentItem, ...action.payload } }
    },
    changeEditedCurrentItem: (state, action: PayloadAction<any>) => {
      return { ...state, editedCurrentItem: { ...state.editedCurrentItem, ...action.payload } }
    },
    deleteAddedItem: (state, action: PayloadAction<Index>) => {
      const addedItems = state.addedItems.filter((item: Item, index) => action.payload.index !== index);
      return { ...state, addedItems }
    },
    deleteById: (state, action: PayloadAction<Id>) => {
      const addedItems = state.addedItems.filter((item: Item) => action.payload.id !== item.id);
      return { ...state, addedItems }
    },
    selectSlide: (state, action: PayloadAction<Index>) => {
      const currentItem = state.addedItems[action.payload.index];
      return {
        ...state,
        selectedSlide: action.payload.index,
        currentItem,
        originalAudio: {
          play: false,
          record: false,
          blob: currentItem.originalAudio[currentItem.targetAudioIndex]
        },
        targetAudio: {
          play: false,
          record: false,
          blob: currentItem.targetAudio[currentItem.targetAudioIndex]
        },
        img: {
          url: (currentItem.suggestedImages.length ? (currentItem.suggestedImages[0].assets.preview.markedImage || currentItem.suggestedImages[0].assets.preview.url) : ''),
          description: (currentItem.suggestedImages.length ? currentItem.suggestedImages[0].description : 'no description'),
          height: (currentItem.suggestedImages.length ? currentItem.suggestedImages[0].assets.preview.height : 290),
          width: (currentItem.suggestedImages.length ? currentItem.suggestedImages[0].assets.preview.width : 350)
        }
      }
    },
    selectFromSuggestedImages: (state, action: PayloadAction<Id>) => {
      const currentItem = state.currentItem;
      return {
        ...state,
        addedItems: state.addedItems
          .map((item: Item) => (item.id === currentItem.id) ? { ...item, suggestedImage: action.payload.id, hasImage: true } : item),
        currentItem: { ...currentItem, suggestedImage: action.payload.id, hasImage: true },
      }
    },
    addSuggestedImages: (state, action: PayloadAction<any>) => {
      const currentItem = state.currentItem;
      const { suggestedImage, suggestedImages } = action.payload;

      return {
        ...state,
        addedItems: state.addedItems
          .map((item: Item) => (item.id === currentItem.id) ? { ...item, suggestedImage, suggestedImages: [...item.suggestedImages, ...suggestedImages] } : item),
        currentItem: { ...currentItem, suggestedImage, suggestedImages: [...currentItem.suggestedImages, ...suggestedImages] },
      }
    },
    changeItemProperty: (state, action: PayloadAction<any>) => {
      const currentItem = state.currentItem;
      const newItem = {
        ...currentItem,
        ...action.payload,
      };
      return {
        ...state,
        currentItem: newItem,
        addedItems: state.addedItems
          .map((item: Item) => (item.id === currentItem.id) ? newItem : item)
      }
    },
    addEmptySlide: (state, action: PayloadAction<any>) => {
      const addedItems = [...state.addedItems, { ...initialItem, id: uuid(), ...action.payload }];
      return { ...state, addedItems }
    },
    addImportedItems: (state, action: PayloadAction<Item[]>) => {
      const items = action.payload.map((item: Item) => ({
        ...item,
        suggestedImage: (item.suggestedImages && item.suggestedImages[0]) ? item.suggestedImages[0].id : ""
      }))
      return { ...state, addedItems: [...state.addedItems, ...items] }
    },
    changeMainState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    changeItem: (state, action: PayloadAction<any>) => {
      const { suggestedImages } = action.payload;
      const currentItem = state.currentItem;
      const newItem = {
        ...currentItem,
        ...action.payload,
        ...suggestedImages && { suggestedImages: [...currentItem.suggestedImages, ...action.payload.suggestedImages] }
      };

      const originalAudio = {
        ...(action.payload.originalAudio &&  {
          originalAudio: {
          play: false,
          record: false,
          blob: action.payload.originalAudio[currentItem.originalAudioIndex]
        }}),
      }

      const targetAudio = {
        ...(action.payload.targetAudio &&  {
          targetAudio: {
          play: false,
          record: false,
          blob: action.payload.targetAudio[currentItem.targetAudioIndex]
        }}),
      }
      
      return {
        ...state,
        ...originalAudio,
        ...targetAudio,
        currentItem: newItem,
        addedItems: state.addedItems
          .map((item: Item) => (item.id === currentItem.id) ? newItem : item)
      }
    },
    changeAudio: (state, action: PayloadAction<any>) => {
      const currentItem = state.currentItem;
      const { currentItemAudio, ...rest } = action.payload;
      const newItem = {
        ...currentItem,
        ...currentItemAudio,
      };
      return {
        ...state,
        ...rest,
        currentItem: newItem,
        addedItems: state.addedItems
          .map((item: Item) => (item.id === currentItem.id) ? newItem : item)
      }
    },
    setLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      return { ...state, ...action.payload }
    },
    resetStore: (state, action: PayloadAction<any>) => {
      return { ...state, ..._.omit(initialState, ['languages', 'interests', 'topics']) };
    },
  },
});

export const {
  changeFilters,
  setLoadingStatus,
  changeCurrentItem,
  changeMarkdownLocation,
  changeEditedCurrentItem,
  deleteAddedItem,
  addEmptySlide,
  selectSlide,
  changeOriginalLanguage,
  changeTargetLanguage,
  addImportedItems,
  changeItem,
  selectFromSuggestedImages,
  addSuggestedImages,
  changeItemProperty,
  resetStore,
  changeMainState,
  changeAudio,
  changeInterest,
  changeTopic
} = contentManagementSlice.actions;


export const getLanguages = async () => {
  const messageKey = uuid();
  message.loadingMessage('loading languages ...', messageKey, 0);
  try {
    const { data: { results: languages } } = await axios({
      method: 'GET',
      url: `${API_URL}/languages`,
    });
    antMessage.destroy();
    return languages;
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const getInterests = async (token: string) => {
  const messageKey = uuid();
  message.loadingMessage('loading interests ...', messageKey, 0);

  try {
    const { data: { results: interests } } = await axios({
      method: 'GET',
      url: `${API_URL}/interests`,
      headers: {
        Authorization: token
      }
    });
    antMessage.destroy();
    return interests;
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const getTopics = async (token: string) => {
  const messageKey = uuid();
  message.loadingMessage('loading topics ...', messageKey, 0);

  try {
    const { data: { results: topics } } = await axios({
      method: 'GET',
      url: `${API_URL}/topics`,
      headers: {
        Authorization: token
      }
    });
    antMessage.destroy();
    return topics;
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};


export const getInitialData = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('loading initial data..', messageKey, 0);
  try {
    const [{ interests }, { topics }, { languages }] = await Promise.all([getInterests(token), getTopics(token), getLanguages()]);
    dispatch(contentManagementSlice.actions.changeInitials({ languages, interests, topics }));
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const getSlides = (token: string, query: string, pagination: Pagination): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('loading slides..', messageKey, 0);
  try {
    const { data: { results: { slides, total } } } = await axios({
      method: 'GET',
      url: `${API_URL}/slides?${query}&limit=${pagination.pageSize}&offset=${pagination.current}`,
      headers: {
        Authorization: token
      },
    });
    antMessage.destroy();
    const formattedSlides = slides
      .map((slide: any) => ({
        ...slide,
        suggestedImage: slide.suggestedImages.id,
        suggestedImages: [slide.suggestedImages],
        hasImage: slide.hasImage === 'true',
        hasOriginalAudio: slide.hasOriginalAudio === 'true',
        hasTargetAudio: slide.hasTargetAudio === 'true'
      })
      );
    dispatch(contentManagementSlice.actions.changeMainState({ addedItems: formattedSlides, pagination: { total } }));
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const exportItemsFile = (query: string, token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('exporting excel file..', messageKey, 0);
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/slides/export?${query}`,
      responseType: 'blob',
      headers: {
        Authorization: token
      },
    });
    fileDownload(data, `items.xlsx`);
    antMessage.destroy();
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const downloadItemsSampleFile = (token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  message.loadingMessage('exporting excel file..', messageKey, 0);
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${API_URL}/slides/download/sample`,
      responseType: 'blob',
      headers: {
        Authorization: token
      },
    });
    fileDownload(data, `Items Sample.csv`);
    antMessage.destroy();
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const generateImageMark = (currentItem: Item, markdown: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left' | '', token: string): AppThunk => async (dispatch) => {
  const messageKey = uuid();
  message.loadingMessage('processing Image ...', messageKey, 0);
  try {
    const { suggestedImage, suggestedImages } = currentItem;
    
    const selectedImageUrl = suggestedImages
      .find((image: any) => (image.id === suggestedImage))?.assets.preview.url;
    const { imageFile, width, height } = await addMarkDownToPic(selectedImageUrl, markdown, currentItem.originalLabel, currentItem.crop);
    const fileUrl = await uploadImageFile(imageFile, token);
    antMessage.destroy();
    dispatch(contentManagementSlice.actions.changeMarkdownLocation({ baseImage: fileUrl, markdown, url: selectedImageUrl, height, width }));
  } catch (err) {
    message.errorMessage(err, messageKey);
  }
};

export const generateWordContent = (originalLanguage: string, targetLanguage: string, word: string, sequence: number, token: string, searchApi: string): AppThunk =>
  async (dispatch) => {
    const messageKey = uuid();
    if (!originalLanguage || !targetLanguage)
      return message.infoMessage('please select languages.', messageKey);
    if (!sequence)
      return message.infoMessage('please select sequence.', messageKey);

    message.loadingMessage('loading word content ...', messageKey, 0);

    try {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results } } = await axios({
        method: 'POST',
        url: `${API_URL}/language/${originalLanguage}/language/${targetLanguage}/word?searchApi=${searchApi}`,
        data: {
          word,
          sequence
        },
        headers: {
          Authorization: token
        }
      });
      const suggestedImage = results.suggestedImages.length ? results.suggestedImages[0].id : '';
      antMessage.destroy();
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
      dispatch(contentManagementSlice.actions.changeItem({ ...results, suggestedImage }));

    } catch (err) {
      message.errorMessage(err, messageKey);
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const searchWordSuggestedImages = (word: string, page: number, token: string, isSuggestedImage: any, searchApi: string,  originalLanguage: string, targetLanguage: string): AppThunk =>
  async (dispatch) => {

    const messageKey = uuid();
    if (!word)
      return message.infoMessage('please add a word  search for!', messageKey);

    message.loadingMessage('loading suggested images ...', messageKey, 0);

    try {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results } } = await axios({
        method: 'POST',
        url: `${API_URL}/shutter/suggested?searchApi=${searchApi}&originalLanguage=${originalLanguage}&targetLanguage=${targetLanguage}`,
        data: {
          word,
          page
        },
        headers: {
          Authorization: token
        }
      });
      if (!isSuggestedImage) {
        const suggestedImage = results.suggestedImages.length ? results.suggestedImages[0].id : '';
        dispatch(contentManagementSlice.actions.changeItem({ ...results, suggestedImage }));
      } else {
        dispatch(contentManagementSlice.actions.changeItem({ ...results }));
      }
    } catch (err) {
      message.errorMessage(err, messageKey);
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    } finally {
      antMessage.destroy();
    }
  };

export const saveItems = (items: any[], token: string): AppThunk =>
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('saving...', messageKey, 0);

    try {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
      await axios({
        method: 'POST',
        url: `${API_URL}/slides/save`,
        data: { slides: items },
        headers: {
          Authorization: token
        }
      });
      message.successMessage('saved successfully', messageKey);
      dispatch(contentManagementSlice.actions.resetStore({}));
    } catch (err) {
      message.errorMessage(err, messageKey);
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const deleteSlide = (id: string, token: string): AppThunk =>
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('deleting slide...', messageKey, 0);

    try {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results: { deletedId } } } = await axios.delete(`${API_URL}/slides/${id}`, {
        headers: {
          Authorization: token
        }
      });
      message.successMessage('deleted successfully', messageKey);
      dispatch(contentManagementSlice.actions.deleteById({ id: deletedId }));
    } catch (err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const updateSlide = (item: any, token: string): AppThunk =>
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('updating slide...', messageKey, 0);
    try {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
      await axios({
        method: 'PUT',
        url: `${API_URL}/slides/${item[0].id}`,
        data: {
          slides: [_.omit(item[0], ['targetLang', 'originalLang', 'interest', 'topic'])],
        },
        headers: {
          Authorization: token
        }
      });

      dispatch(contentManagementSlice.actions.changeItemProperty(item[0]));

      message.successMessage('updated successfully', messageKey);
    } catch (err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };

export const fetchSingleWordAudio = (word: string, language: string, token: string, type: 'originalAudio' | 'targetAudio', currentAudios: string[]): AppThunk =>
  async (dispatch) => {
    const messageKey = uuid();
    if (!word)
      return message.infoMessage('please add a word to fetch', messageKey);
    if (!language)
      return message.infoMessage('please select a language', messageKey);
    message.loadingMessage('fetching audio ...', messageKey, 0);

    try {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
      const { data: { results: { audio } } } = await axios({
        method: 'POST',
        url: `${API_URL}/textToSpeach/language/${language}`,
        data: {
          word,
        },
        headers: {
          Authorization: token
        }
      });
      const newAudios = [...currentAudios, audio];
      dispatch(contentManagementSlice.actions.changeAudio({ 
        currentItemAudio: {
          [type]: newAudios, 
          [`${type}Index`]: newAudios.length - 1,
        },
        [type]: {
          play: false,
          record: false,
          blob: audio
        },
        [`has${type.charAt(0).toUpperCase() + type.slice(1)}`]: true
       }));
      antMessage.destroy();
    } catch (err) {
      message.errorMessage(err, messageKey);
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };


  export const saveItemsBulk = (itemsToUpdate: ReactText[], updatedItems: any[], currentItems: Item[],interest: string | undefined, topic: string | undefined, token: string): AppThunk => 
  async (dispatch) => {
    const messageKey = uuid();
    message.loadingMessage('saving items...', messageKey, 0);
    
    try{
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: true }));
       await axios({
        method: 'PUT',
        url: `${API_URL}/slides/save/bulk`,
        data: { slides: updatedItems },
        headers: {
          Authorization: token
        }
      });
      const addedItems = currentItems
        .map((item) => itemsToUpdate.includes(item.id) ? {
          ...item,
           ...updatedItems.find((ite) => ite.id === item.id),
           ...(interest && { interest }),
           ...(topic && { topic }),
        } : item);
      message.successMessage('saved successfully', messageKey);
      dispatch(contentManagementSlice.actions.changeMainState({ addedItems }));
    } catch(err) {
      message.errorMessage(err, messageKey);
    } finally {
      dispatch(contentManagementSlice.actions.setLoadingStatus({ isLoading: false }));
    }
  };



export const getItems = (pagination: Pagination, token: string | null): AppThunk => async (dispatch) => {
  const messageKey = uuid();

  try {
    const { data: { results: { items } } } = await axios({
      method: 'GET',
      url: `${API_URL}/topics`,
      headers: {
        Authorization: token
      }
    });
    dispatch(contentManagementSlice.actions.changeItems(items));
  } catch (err) {
    message.errorMessage(err, messageKey);
  } finally {
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLanguages = (state: RootState) => state.conetentManagement.languages;
export const selectInterests = (state: RootState) => state.conetentManagement.interests;
export const selectAllTopics = (state: RootState) => state.conetentManagement.topics;
export const selectFilters = (state: RootState) => state.conetentManagement.filters;
export const selectIsLoading = (state: RootState) => state.conetentManagement.isLoading;
export const selectItems = (state: RootState) => state.conetentManagement.items;
export const selectPagination = (state: RootState) => state.conetentManagement.pagination;
export const selectIsFetching = (state: RootState) => state.conetentManagement.isFetching;
export const selectCurrentItem = (state: RootState) => state.conetentManagement.currentItem;
export const selectEditedCurrentItem = (state: RootState) => state.conetentManagement.editedCurrentItem;
export const selectOriginalAudio = (state: RootState) => state.conetentManagement.originalAudio;
export const selectTargetAudio = (state: RootState) => state.conetentManagement.targetAudio;
export const selectAddedItems = (state: RootState) => state.conetentManagement.addedItems;
export const selectSelectedSlide = (state: RootState) => state.conetentManagement.selectedSlide;
export const selectOriginalLanguage = (state: RootState) => state.conetentManagement.originalLanguage;
export const selectTargetLanguage = (state: RootState) => state.conetentManagement.targetLanguage;
export const selectInterest = (state: RootState) => state.conetentManagement.interestId;
export const selectTopic = (state: RootState) => state.conetentManagement.topicId;
export const selectContentManagementState = (state: RootState) => state.conetentManagement;
export const selectCurrentItemSelectedImage = (imgSize: string) => (state: RootState) => {
  const { suggestedImage, suggestedImages } = state.conetentManagement.currentItem;
  const image = suggestedImages.filter((image: any) => image.id === suggestedImage);
  if (image.length) {
    const newImage = image[0].assets[imgSize];

    return {
      url: newImage.markedImage || newImage.url,
      description: image[0].description,
      height: newImage.height,
      width: newImage.width,
    };
  }
  return { url: 'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg', description: '' };
};
export const selectTopics = (state: RootState) => {
  const { currentItem, topics } = state.conetentManagement;
  return topics.filter((topic: Topic) => topic.interestId === currentItem.interestId);
};
export default contentManagementSlice.reducer;
